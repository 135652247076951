<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-breadcrumbs :items="$route.meta.breadcrumbs" large></v-breadcrumbs>
    <!-- <v-toolbar-title class="font-weight-light" v-text="$route.name" /> -->

    <v-spacer />

    <!-- <v-text-field :label="$t('search')" color="secondary" hide-details style="max-width: 165px;">
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <div class="mx-3" />

    <!-- <v-btn class="ml-2" min-width="0" text to="/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

    <!-- <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->
    <v-btn class="ml-3" text v-if="showTimbres">
      Timbres:  <strong>{{numeroTimbres}}</strong>
    </v-btn>
    <v-btn class="ml-2" min-width="0" text to="/dashboard/perfil">
      <v-icon left >mdi-account</v-icon>
      {{$store.state.user.name}}
    </v-btn>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'

  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render(h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
      numeroTimbres:0,
      showTimbres : false
    }),

    computed: {
      ...mapState(['drawer']),
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      getTimbres(){
        const secondsToResend = 60;
        const milisecondsToResend = secondsToResend * 1000;
        this.$http.get('/nuberp/timbres').then(({data}) => {
          console.log("timbres", data)
          this.showTimbres = true;
          if(data && data.data && data.data.timbres_disponibles){
            this.numeroTimbres = data.data.timbres_disponibles;
          }
          setTimeout(()=>{
            this.getTimbres();
          },milisecondsToResend);
        }).catch((error)=>{
          if(error.response.statusCode() != 403){
            setTimeout(()=>{
              this.getTimbres();
            },milisecondsToResend);
          }

        })
      }
    },
    mounted(){
      this.getTimbres();
    }

  }
</script>
